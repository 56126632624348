<template>
  <div class="edit-effective-datetime-sidebar">
    <v-form ref="form" style="width: 500px; padding: 20px">
      <v-layout row>
        <v-flex xs4 style="margin-right: 10px">
          <CRInput
            v-model="date"
            label="Effective Date"
            type="date"
            @input="onEffectiveDateChange"
          />
        </v-flex>
        <v-flex xs4>
          <CRInput
            v-model="time"
            label="Effective Time"
            type="time"
            @input="onEffectiveTimeChange"
          />
        </v-flex>
      </v-layout>
    </v-form>
    <div class="edit-effective-datetime-sidebar--button-spacer"></div>
    <v-btn
      id="edit-effective-datetime-save-button"
      class="edit-effective-datetime-save-button"
      color="primary"
      style="margin: 0; border-radius: 0"
      @click="editPaymentEffectiveDate"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import transaction from '@/services/transaction'
import { EventBus } from '@/utils/event-bus'
export default {
  props: {
    transactionId: {
      type: Number,
      default: null,
    },
    paymentId: {
      type: Number,
      default: null,
    },
    refundId: {
      type: Number,
      default: null,
    },
    effectiveDatetime: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      date: '',
      time: '',
      offset: '',
    }
  },
  computed: {
    datetime() {
      return `${this.date}T${this.time}${this.offset}`
    },
  },
  mounted() {
    this.setEffectiveDate(this.effectiveDatetime)
    this.setEffectiveTime(this.effectiveDatetime)
    this.setOffset(this.effectiveDatetime)
  },
  methods: {
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    setEffectiveDate(datetime) {
      this.date = DateTime.fromISO(datetime).toISODate()
    },
    setEffectiveTime(datetime) {
      this.time = DateTime.fromISO(datetime).toISOTime({
        includeOffset: false,
      })
    },
    setOffset(datetime) {
      const offsetDatetime = DateTime.fromISO(datetime).toISOTime()
      this.offset = offsetDatetime.substr(offsetDatetime.length - 6)
    },
    async editPaymentEffectiveDate() {
      const payload = {
        effectiveDatetime: DateTime.fromISO(this.datetime).toUTC().toISO(),
        transactionId: this.transactionId,
        paymentId: this.paymentId,
        refundId: this.refundId,
      }
      await transaction.updateEffectiveDate(payload)
      EventBus.$emit('refresh-reservation-payments')
      this.close()
    },
    onEffectiveDateChange(event) {
      this.date = event
    },
    onEffectiveTimeChange(event) {
      this.time = event
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-effective-datetime-sidebar {
  height: 100%;
  width: 500px;

  &--button-spacer {
    min-height: 71px;
    width: 100%;
  }
}

.edit-effective-datetime-save-button {
  display: flex;
  position: fixed;
  flex-direction: column;
  font-size: 18px;
  color: white;
  bottom: 0;
  width: inherit;
  height: 71px;
  padding: 24px 0;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  width: 500px;
}
</style>
